import { useState } from "react";
import { Field } from "formik"
import { Autocomplete, Card, CardContent, CardHeader, Grid, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { useUser } from 'contexts/userContext';
import toApiValuesCustom from "pages/auth/Orders/to-api-values-custom";
import toFormValuesCustom from "pages/auth/Orders/to-form-values-custom";
import FormField from "../FormField"

const UserInformation = (props) => {
    const {
        fields,
        getApiError,
        basket,
        setBasket,
        formik,
        resource,
    } = props;

    const [userChoices, setUserChoices] = useState([]);
    const [learningGroupChoices, setLearningGroupChoices] = useState([
        { value: 'none', label: 'Select a user before selecting a learning group' }
    ]);
    const { setUserProfile } = useUser();

    const changeLearningGroupContext = async (learningGroupIri) => {
        const response = await requestApi.putResponse({
            url: 'learning-group-context',
            data: { learningGroup: learningGroupIri }
        });
        const decodedToken = jwtDecode(response.token);
        if (decodedToken) {
            setUserProfile(decodedToken);
            localStorage.setItem('accessToken', response.token);
            localStorage.setItem('refreshToken', response.refresh_token);
        }
    }

    const handleLearningGroupOnChange = async (value) => {
        const selectedLearningGroupId = value.target.value;
        await changeLearningGroupContext(selectedLearningGroupId);

        const newValues = { ...formik.values };

        const response = await resourceApi.saveResource({
            apiEndpoint: 'baskets',
            id: basket.id,
            data: toApiValuesCustom(newValues, fields)
        });
        if (response.resource) {
            setBasket(response.resource);
            // eslint-disable-next-line no-shadow
            const formValues = toFormValuesCustom(response.resource, fields);
            formValues.learningGroup = selectedLearningGroupId;
            formik.setValues(formValues);
            formik.setTouched(formValues);
        }
    }

    const searchUsers = async (event) => {
        const search = event.target.value;
        if (search.length) {
            const choices = await requestApi.getResponse({ url: `users/choices?search=${encodeURIComponent(search)}` });
            setUserChoices(choices);
        } else {
            setUserChoices([]);
        }
    };

    const handleUserOnChange = async (value) => {
        const matches = value.match(/^\/users\/(\d+?)$/);
        if (matches) {
            const user = await resourceApi.getResource({ apiEndpoint: 'users', id: matches[1] });
            const newValues = { ...formik.values };
            newValues.user = value;
            newValues.deliveryAddress.firstName = user.firstName;
            newValues.deliveryAddress.lastName = user.lastName;
            newValues.deliveryAddress.phone = user.phone;
            newValues.email = user.email;
            if (user.addresses && user.addresses.length > 0) {
                const address = user.addresses[0];
                if (!newValues.billingAddress.line1) newValues.billingAddress.line1 = address.line1;
                if (!newValues.billingAddress.line2) newValues.billingAddress.line2 = address.line2;
                if (!newValues.billingAddress.townCity) newValues.billingAddress.townCity = address.townCity;
                if (!newValues.billingAddress.county) newValues.billingAddress.county = address.county;
                if (!newValues.billingAddress.postcode) newValues.billingAddress.postcode = address.postcode;
                if (!newValues.billingAddress.country) newValues.billingAddress.country = address.country;
                if (!newValues.deliveryAddress.line1) newValues.deliveryAddress.line1 = address.line1;
                if (!newValues.deliveryAddress.line2) newValues.deliveryAddress.line2 = address.line2;
                if (!newValues.deliveryAddress.townCity) newValues.deliveryAddress.townCity = address.townCity;
                if (!newValues.deliveryAddress.county) newValues.deliveryAddress.county = address.county;
                if (!newValues.deliveryAddress.postcode) newValues.deliveryAddress.postcode = address.postcode;
                if (!newValues.deliveryAddress.country) newValues.deliveryAddress.country = address.country;
            }

            const emptyChoice = { value: 'none', label: 'Select learning group' };
            const choices = user.allLearningGroups?.map((learningGroup) => (
                { value: learningGroup['@id'], label: `${learningGroup.name} (${learningGroup.type.replace('LearningGroup', '')})` }
            ));
            setLearningGroupChoices([emptyChoice, ...choices]);
            formik.setValues(newValues);
            formik.setTouched(newValues);
        }
    };


    return (
        <Card>
            <CardHeader title='User information' />
            <CardContent>
                <Grid container spacing={2} >
                    {!resource.isQuote && (
                        <Grid item xs={12}>
                            <FormField
                                {...fields.find((field) => field.name === 'user')}
                                formik={formik}
                                getApiError={getApiError}
                            />

                            <Field
                                component={Autocomplete}
                                options={userChoices}
                                getOptionLabel={(option) => option.label}
                                renderInput={(fieldParams) => (
                                    <>
                                        <TextField
                                            {...fieldParams}
                                            label="Search for user"
                                            variant="outlined"
                                            onChange={searchUsers}
                                            error={formik.errors.user && true}
                                        />
                                        {(getApiError('user') || formik.errors.user) && <span>{getApiError('user') || formik.errors.user}</span>}
                                    </>
                                )}
                                onChange={async (event, value) => { if (value) { await handleUserOnChange(value.value) } }}
                            />
                        </Grid>
                    )}
                    {
                        basket.owningLearningGroup
                            ?
                            <FormField
                                type="static"
                                value={basket.owningLearningGroup.name}
                            />
                            :
                            <FormField
                                {...fields.find((field) => field.name === 'learningGroup')}
                                choices={learningGroupChoices}
                                onChange={handleLearningGroupOnChange}
                                formik={formik}
                                getApiError={getApiError}
                            />
                    }

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.firstName')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.lastName')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'deliveryAddress.phone')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'email')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />

                    <FormField
                        {...fields.find((field) => field.name === 'billingAddress.email')}
                        formik={formik}
                        getApiError={getApiError}
                        disabled={resource.isQuote}
                    />
                </Grid>
            </CardContent>
        </Card>
    )
}

UserInformation.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    formik: PropTypes.any.isRequired,
    basket: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([null]),
        ]),
        billingAddress: PropTypes.shape({
            company: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            line1: PropTypes.string.isRequired,
            line2: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
            townCity: PropTypes.string.isRequired,
        }).isRequired,
        currency: PropTypes.string.isRequired,
        customerNote: PropTypes.string.isRequired,
        deliveryAddress: PropTypes.shape({
            company: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            line1: PropTypes.string.isRequired,
            line2: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
            townCity: PropTypes.string.isRequired,
        }).isRequired,
        email: PropTypes.string.isRequired,
        isQuote: PropTypes.bool.isRequired,
        learningGroup: PropTypes.string.isRequired,
        manualDiscount: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([null]),
        ]),
        manualShippingServiceAmount: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf([null]),
        ]),
        manualShippingServiceName: PropTypes.string.isRequired,
        paymentMethod: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
        user: PropTypes.string.isRequired,
        owningLearningGroup: PropTypes.shape({
            name: PropTypes.string
        }),
    }).isRequired,
    setBasket: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fields: PropTypes.array.isRequired,
    getApiError: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    resource: PropTypes.object.isRequired,
};

export default UserInformation;