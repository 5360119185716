/* eslint-disable */
import {useCallback, useEffect, useState} from 'react';
import { FieldArray, getIn } from 'formik';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import striptags from 'striptags';

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';

// MDPR
import MDTypography from 'mdpr2/components/MDTypography';

// MDPR
import { resourceApi } from 'api/resource-api';
import FormField from 'components/shared/FormField';
import useMounted from 'hooks/use-mounted';
import MDButton from "../../../mdpr2/components/MDButton";

const PageResources = ({ formik, getApiError, showFileBrowser }) => {
  const [initialised, setInitialised] = useState(false);
  const [resourceMediaTypes, setResourceMediaTypes] = useState([]);
  // 'Delete resource'
  const [deleteResourceConfirmationModalOpen, setDeleteResourceConfirmationModalOpen] = useState(false);
  const [deleteResourceIndex, setDeleteResourceIndex] = useState();
  const [deleteResourceArrayHelpers, setDeleteResourceArrayHelpers] = useState();

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    const resourceMediaTypesResponse = await resourceApi.getResources({
      apiEndpoint: 'resource-media-types',
      sortValues: [{ field: 'displayOrder', direction: 'asc' }],
      pagination: { itemsPerPage: 999 },
    });
    if (isMounted()) {
      setResourceMediaTypes(resourceMediaTypesResponse.resources);
    }

    if (isMounted()) {
      setInitialised(true);
    }
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return '';

  // Define a data prototype for a new Resource - a name and as many ResourceMedias as there are ResourceMediaTypes.
  // See AddEditResourcePage::toFormValuesCustom for an explanation
  const prototype = {};
  prototype.name = '';
  prototype.resourceMedias = [];
  resourceMediaTypes.forEach(resourceMediaType => {
    const resourceMedia = {};
    resourceMedia.resourceMediaType = resourceMediaType;
    resourceMedia.externalUri = '';
    prototype.resourceMedias.push(resourceMedia);
  });

  const handleResourceDragEnd = (formik, result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const { resources } = formik.values;
    const reorderedResources = [];
    const movedResource = resources[result.source.index];
    resources.forEach((resource, index) => {
      if (index === result.destination.index && result.destination.index < result.source.index) {
        reorderedResources.push(movedResource);
      }
      if (index !== result.source.index) {
        reorderedResources.push(resource);
      }
      if (index === result.destination.index && result.destination.index >= result.source.index) {
        reorderedResources.push(movedResource);
      }
    });
    formik.setFieldValue('resources', reorderedResources);
  };

  const handleDeleteResource = (index, arrayHelpers) => {
    setDeleteResourceIndex(index);
    setDeleteResourceArrayHelpers(arrayHelpers);
    setDeleteResourceConfirmationModalOpen(true);
  };
  const confirmDeleteResource = () => {
    deleteResourceArrayHelpers.remove(deleteResourceIndex);
    setDeleteResourceConfirmationModalOpen(false);
  };

  const renderResource = (resource, index) => {
    return (
      <Grid
        container
        mt={1}
        spacing={2}
      >
        <FormField
          name={`resources[${index}].name`}
          label="Name"
          formik={formik}
          getApiError={getApiError}
        />

        <FormField
          name={`resources[${index}].isPublic`}
          type="checkbox"
          label="Publicly accessible?"
          formik={formik}
          getApiError={getApiError}
        />

        <FormField
          name={`resources[${index}].isGated`}
          type="checkbox"
          label="Gated"
          formik={formik}
          getApiError={getApiError}
        />

        {
          resource.resourceMedias.map((resourceMedia, resourceMediaIndex) => {
            return (
              <FormField
                name={`resources[${index}].resourceMedias[${resourceMediaIndex}].externalUri`}
                type="filePicker"
                label={`${resourceMedia.resourceMediaType.name}`}
                showFileBrowser={showFileBrowser}
                formik={formik}
                getApiError={getApiError}
                key={`form-field-resource-${resource.id}-resourceMediaIndex-${resourceMediaIndex}`}
              />
            );
          })
        }
      </Grid>
    );
  }

  return (
    <>
      <Grid
        item
        key="grid-resources"
        md={12}
        xs={12}
      >
        <FieldArray
          name="resources"
          render={(fieldArrayHelpers) => (
            <>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom={2}
              >
                <Grid item>
                  <MDTypography variant="h6">
                    Resources
                  </MDTypography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => fieldArrayHelpers.push(prototype)}
                    startIcon={<AddIcon fontSize="small" />}
                    variant="contained"
                  >
                    Add resource
                  </Button>
                </Grid>
              </Grid>
              <DragDropContext onDragEnd={(result) => handleResourceDragEnd(formik, result)}>
                <Droppable
                  direction="vertical"
                  droppableId="accordion-droppable"
                >
                  {
                    (droppableProvided) => (
                      <Box
                        ref={droppableProvided.innerRef}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...droppableProvided.droppableProps}
                      >
                        {
                          getIn(formik.values, 'resources')?.map((resourceValue, resourceIndex) => {
                            return (
                              <Draggable
                                draggableId={`accordion-${resourceValue['@id'] || resourceValue.uuid}`}
                                index={resourceIndex}
                                key={`accordion-${resourceValue['@id'] || resourceValue.uuid}`}
                              >
                                {
                                  (draggableProvided, snapshot) => (
                                    <Accordion
                                      key={`accordion-resources-${resourceValue['@id'] || resourceValue.uuid}`}
                                      TransitionProps={{ unmountOnExit: true }}
                                      ref={draggableProvided.innerRef}
                                      /* eslint-disable-next-line react/jsx-props-no-spreading */
                                      {...draggableProvided.draggableProps}
                                      style={{
                                        ...draggableProvided.draggableProps.style,
                                        background: snapshot.isDragging && 'rgba(0, 0, 0, 0.10)',
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{
                                          background: snapshot.isDragging ? 'none' : 'rgba(0, 0, 0, .025)',
                                        }}
                                      >
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                        >
                                          <Grid item>
                                            <Grid
                                              container
                                              spacing={2}
                                            >
                                              <Grid item>
                                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                                <div {...draggableProvided.dragHandleProps}>
                                                  <DragHandleIcon />
                                                </div>
                                              </Grid>
                                              <Grid item>
                                                <MDTypography variant="subtitle2">
                                                  {striptags(resourceValue.name)}
                                                </MDTypography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item>
                                            <IconButton
                                              color="default"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                fieldArrayHelpers.insert(resourceIndex + 1, prototype);
                                              }}
                                              size="small"
                                            >
                                              <AddIcon fontSize="small" />
                                              <ArrowDownwardIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                              color="error"
                                              onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteResource(resourceIndex, fieldArrayHelpers);
                                              }}
                                              size="small"
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {renderResource(resourceValue, resourceIndex)}
                                      </AccordionDetails>
                                    </Accordion>
                                  )
                                }
                              </Draggable>
                            );
                          })
                        }
                        {droppableProvided.placeholder}
                      </Box>
                    )
                  }
                </Droppable>
              </DragDropContext>
            </>
          )}
        />
      </Grid>
      {
        deleteResourceConfirmationModalOpen && (
          <Dialog
            open
            aria-labelledby="delete-resource"
            aria-describedby="delete-resource"
          >
            <DialogTitle id="scroll-dialog-title">Delete resource</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this resource?
            </DialogContent>
            <DialogActions>
              <MDButton
                color="info"
                disabled={formik.isSubmitting}
                onClick={confirmDeleteResource}
                variant="gradient"
              >
                Yes
              </MDButton>
              <MDButton
                color="secondary"
                disabled={formik.isSubmitting}
                onClick={() => setDeleteResourceConfirmationModalOpen(false)}
                variant="gradient"
              >
                Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        )
      }
    </>
  );
}

PageResources.propTypes = {
  formik: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
};

PageResources.defaultProps = {
};

export default PageResources;
