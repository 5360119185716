import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import AddEditResource from 'components/shared/AddEditResource';
import { toApiValues } from 'components/shared/resource/api-form-mapper';

import AddOnboardingApplicationForm from './AddOnboardingApplicationForm';

const apiEndpoint = 'admin/infinity/onboarding-applications';
const adminEndpoint = 'infinity/onboarding-applications';
const resourceName = 'onboarding application ';

const validationSchema = Yup.object().shape({
  user: Yup.string().required('User is required'),
  learningGroup: Yup.string().required('Learning group is required'),
});


const AddOnboardingApplication = () => {
  const params = useParams();

  const id = params.id ? Number(params.id) : null;

  const fields =  [
    {
      name: 'basket',
      label: 'basket',
      type: 'hidden',
    },
    {
      name: 'learningGroup',
      label: 'Learning group',
      defaultValue: 'Select user first',
      type: 'hidden',
    },
    {
      name: 'user',
      label: 'User',
      type: 'hidden',
      flex: 6,
    },
    {
      name: 'firstName',
      label: 'First name',
      type: 'text',
      section: 'basket',
      flex: 6,
    },
    {
      name: 'lastName',
      label: 'Last name',
      type: 'text',
      section: 'basket',
      flex: 6,
    },
    {
      name: 'phone',
      label: 'Phone number',
      type: 'text',
      section: 'basket',
      flex: 6,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      section: 'basket',
      flex: 6,
    },
    {
      name: 'urn',
      type: 'hidden',
    },
    {
      name: 'schoolName',
      type: 'hidden',
    },    {
      name: 'schoolPostcode',
      type: 'hidden',
    },
  ];

  const toApiValuesCustom = (formValues) => {
    const apiValues = toApiValues(formValues, fields);

    apiValues.basket = apiValues.basket.id ? `baskets/${apiValues.basket.id}` : null;

    return apiValues;
  };


  return (
    <AddEditResource
      addEditForm={AddOnboardingApplicationForm}
      apiEndpoint={apiEndpoint}
      adminEndpoint={adminEndpoint}
      fields={fields}
      id={id}
      toApiValuesCustom={toApiValuesCustom}
      resourceName={resourceName}
      validationSchema={validationSchema}
      redirectToEdit
    />
  );
};

export default AddOnboardingApplication;
