export const adminSession = 'accessToken';
export const refreshToken = 'refreshToken';
export const clientTimeOffset = 'clientTimeOffset';

export const defaultOrderShipments = [
  {
    value: '/order-shipments/1',
    label: 'Main',
  },
  {
    value: '/order-shipments/2',
    label: 'Fast Finishers',
  },
  {
    value: '/order-shipments/3',
    label: 'Autumn',
  },
  {
    value: '/order-shipments/4',
    label: 'Spring',
  },
  {
    value: '/order-shipments/5',
    label: 'Summer',
  },
];
