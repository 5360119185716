import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditWebComponentForm from './AddEditWebComponentForm';

const apiEndpoint = 'web-components';
const resourceName = 'web component';

const visibilityChoices = [
  {
    value: 'public',
    label: 'Public: visible to everyone',
  },
  {
    value: 'private',
    label: 'Private: only visible to site admins',
  },
];

const fields = [
  {
    name: 'name',
    label: 'Name'
  },
  {
    name: 'slug',
    label: 'Slug'
  },
  {
    name: 'visibility',
    label: 'Visibility',
    type: 'select',
    choices: visibilityChoices,
  },
  {
    name: 'isArchived',
    label: 'Archived',
    type: 'checkbox',
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  slug: Yup.string()
    .required('Slug is required'),
});

// eslint-disable-next-line no-shadow
const toFormValuesCustom = (resource, fields) => {
  // Perform standard mapping
  const formValues = toFormValues(resource, fields);

  // visibility is an array in the data, map it to a scalar, default public
  formValues.visibility = resource.visibility[0] ?? 'public';

  // Additional mapping for pageBlocks
  formValues.pageBlocks = [];
  resource.pageBlocks?.forEach(pageBlock => {
    const pageBlockFormValues = {};
    // Set @id, pageBlockType and identifier form values for the PageBlock
    pageBlockFormValues['@id'] = pageBlock['@id'];
    pageBlockFormValues.pageBlockType = pageBlock.pageBlockType['@id'];
    pageBlockFormValues.identifier = pageBlock.identifier;
    // Set the content form values for the PageBlock
    // If content is empty, it arrives here as an empty array - we need to cast this into an object
    if (Object.prototype.toString.call(pageBlock.content) === '[object Array]') {
      pageBlockFormValues.content = {};
    } else {
      pageBlockFormValues.content = pageBlock.content;
    }
    formValues.pageBlocks.push(pageBlockFormValues);
  })

  return formValues;
}

// eslint-disable-next-line no-shadow
const toApiValuesCustom = (formValues, fields) => {
  // Perform standard mapping
  const apiValues = toApiValues(formValues, fields);

  // visibility is a scalar in the data, map it to an array, default public
  apiValues.visibility = formValues.visibility ? [formValues.visibility] : ['public'];

  // Additional mapping for pageBlocks
  apiValues.pageBlocks = [];
  formValues.pageBlocks.forEach(pageBlock => {
    const pageBlockApiValues = {};
    // Set @id, pageBlockType and identifier API values for the PageBlock
    pageBlockApiValues['@id'] = pageBlock['@id'];
    pageBlockApiValues.pageBlockType = pageBlock.pageBlockType;
    pageBlockApiValues.identifier = pageBlock.identifier;
    // Set the content API values for the PageBlock
    pageBlockApiValues.content = pageBlock.content;
    apiValues.pageBlocks.push(pageBlockApiValues);
  })

  return apiValues;
};

const AddEditWebComponent = () => {
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  return (
    <AddEditResource
      addEditForm={AddEditWebComponentForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

export default AddEditWebComponent;
