import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Autocomplete, Card, CardActions, CardContent, Grid, TextField } from '@mui/material';
import MDButton from 'mdpr2/components/MDButton';
import { useState } from 'react';
import toast from 'react-hot-toast';

// WRM
import Form from 'components/shared/Form';
import FormField from 'components/shared/FormField';
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import changeLearningGroupContext from 'utils/changeLearningGroupContext';
import { useUser } from 'contexts/userContext';

const AddOnboardingApplicationForm = (props) => {
  const { fields, formValues, getApiError, onFormSubmit, validationSchema } = props;
  const { setUserProfile } = useUser();

  const [user, setUser] = useState(null);
  const [userChoices, setUserChoices] = useState([]);
  const [learningGroupChoices, setLearningGroupChoices] = useState([]);

  const searchUsers = async (event) => {
    const search = event.target.value;
    if (search.length) {
      const choices = await requestApi.getResponse({ url: `users/choices?search=${encodeURIComponent(search)}` });
      setUserChoices(choices);
    } else {
      setUserChoices([]);
    }
  };

  const handleUserOnChange = async (event, value, formik) => {
    const newValues = { ...formik.values };

    const matches = value.match(/^\/users\/(\d+?)$/);
    if (!matches) {
      return;
    }
    newValues.user = value;
    await resourceApi
      .getResource({ apiEndpoint: 'users', id: matches[1] })
      .then((response) => {
        setUser(response);
        // Get choices for learning group
        const choices = response.allLearningGroups?.map((learningGroupChoice) => ({
          value: learningGroupChoice['@id'],
          label: `${learningGroupChoice.name} (${learningGroupChoice.type.replace('LearningGroup', '')})`,
        }));
        setLearningGroupChoices(choices);

        // Set default details from user
        newValues.learningGroup = choices[0].value ?? '';
        newValues.firstName = response.firstName ?? '';
        newValues.lastName = response.lastName ?? '';
        newValues.phone = response.phone ?? '';
        newValues.email = response.email ?? '';

        formik.setValues(newValues);
        formik.setTouched(newValues);
      })
      .catch((error) => {
        toast.error('Error getting user');
        console.log(error);
      });
  };

  const getLearningGroupInfo = async (learningGroupIri) => {
    const matches = learningGroupIri.match(/learning-groups\/(\d+?)$/);
    if (matches) {
      const response = await resourceApi.getResource({
        apiEndpoint: 'learning-groups',
        id: matches[1],
      });
      return response;
    }
    return null;
  };

  const handleCreate = async (formik) => {
    const learningGroup = await getLearningGroupInfo(formik.values.learningGroup, formik);
    // Change learning group context to create basket needed for application
    await changeLearningGroupContext(formik.values.learningGroup, setUserProfile);
    // If formik value is empty string, use null
    const address = {
      firstName: formik.values.firstName || null,
      lastName: formik.values.lastName || null,
      line1: learningGroup?.school?.name || null,
      line2: learningGroup?.school?.addressStreet || null,
      townCity: learningGroup?.school?.addressTownCity || null,
      postcode: learningGroup?.school?.addressPostcode || null,
      country: learningGroup?.school?.addressCountry ?? 'GBR',
      phone: formik.values.phone || null,
      email: formik.values.email || null,
    };
    const basketResponse = await resourceApi.saveResource({
      apiEndpoint: 'baskets',
      data: {
        source: 'onboarding-application',
        user: formik.values.user,
        email: user.email,
        owningLearningGroup: formik.values.learningGroup,
        currency: 'GBP',
        billingAddress: address,
        deliveryAddress: address,
      },
    });
    formik.setFieldValue('urn', learningGroup?.school?.urn);
    formik.setFieldValue('schoolName', learningGroup?.name);
    formik.setFieldValue('schoolPostcode', learningGroup?.school?.addressPostcode);
    formik.setFieldValue('basket.id', basketResponse.resource.id);

    await formik.handleSubmit();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={onFormSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <Form formik={formik}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
                  <FormField
                    {...fields.find((field) => field.name === 'user')}
                    formik={formik}
                    getApiError={getApiError}
                  />

                  <Field
                    component={Autocomplete}
                    options={userChoices}
                    getOptionLabel={(option) => option.label}
                    renderInput={(fieldParams) => (
                      <>
                        <TextField
                          {...fieldParams}
                          label="Search for user"
                          variant="outlined"
                          onChange={searchUsers}
                          error={formik.errors.user && true}
                        />
                        {(getApiError('user') || formik.errors.user) && (
                          <span>{getApiError('user') || formik.errors.user}</span>
                        )}
                      </>
                    )}
                    onChange={async (event, value) => {
                      if (value) {
                        await handleUserOnChange(event, value.value, formik);
                      }
                    }}
                  />
                  <FormField
                    {...fields.find((field) => field.name === 'learningGroup')}
                    type={formik.values.user === '' ? 'text' : 'select'}
                    disabled={formik.values.user === ''}
                    choices={learningGroupChoices}
                    formik={formik}
                    getApiError={getApiError}
                  />
                  <Grid container spacing={2}>
                    {fields.map(
                      (mappedField) =>
                        mappedField.section === 'basket' && (
                          <FormField
                            {...mappedField}
                            key={mappedField.name}
                            formik={formik}
                            getApiError={getApiError}
                          />
                        )
                    )}
                  </Grid>
                </CardContent>
                <CardActions sx={{ flexWrap: 'wrap', m: 1 }}>
                  <MDButton
                    color="info"
                    disabled={formik.isSubmitting}
                    variant="gradient"
                    onClick={() => formik.validateForm().then(() => handleCreate(formik))}
                  >
                    Save
                  </MDButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

AddOnboardingApplicationForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object.isRequired,
  getApiError: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  refreshResource: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  validationSchema: PropTypes.object.isRequired,
};

export default AddOnboardingApplicationForm;
