import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

// WRM
import { requestApi } from 'api/request-api';
import { resourceApi } from 'api/resource-api';
import { toFormValues, toApiValues } from 'components/shared/resource/api-form-mapper';
import AddEditResource from 'components/shared/AddEditResource';
import { useAppContext } from 'contexts/app-context';
import useMounted from 'hooks/use-mounted';
import countriesArr from 'utils/countriesList';
import AddEditProductForm from './AddEditProductForm';

const apiEndpoint = 'products';
const resourceName = 'product';

const countryChoices  = countriesArr.map(choice => ({ value: choice[0], label: choice[1] }));

const productOptionGetHeading = (values) => values.name;
const productOptionOptionGetHeading = (values) => values.label;
const orderDataFieldsGetHeading = (values) => values.label;
const orderDataFieldsChoicesGetHeading = (values) => values.label;

const buildFields = (
    productAttributeSetChoices,
    membershipPlanChoices,
    productCategoryChoices,
    productReportingCategoryChoices,
    departmentChoices
    ) => {

  const fields = [
    {
      name: 'productAttributeSet',
      label: 'Product attribute set',
      type: 'select',
      choices: productAttributeSetChoices,
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      choices: [
        {
          value: 'simple',
          label: 'Simple (no variants)',
        },
        {
          value: 'variable',
          label: 'Variable (has variants)',
        },
        {
          value: 'group',
          label: 'Product group',
        },
      ],
      defaultValue: 'simple',
    },
    {
      name: 'sku',
      label: 'SKU',
    },
    {
      name: 'slug',
      label: 'Slug',
    },
    {
      name: 'imageUrl',
      label: 'Image',
      type: 'filePicker',
    },
    {
      name: 'additionalMediaUrls',
      label: 'Additional Media',
      type: 'fieldCollection',
      getHeading: () => 'Additional Media',
      childFields: [
        {
          name: 'image',
          label: 'Image',
          type: 'filePicker'
        },
      ]
    },
    {
      name: 'price',
      label: 'Price',
      type: 'currency',
    },
    {
      name: 'warehouseValue',
      label: 'Warehouse value',
      type: 'currency',
    },
    {
      name: 'packingUnits',
      label: 'Packing units',
      type: 'decimal',
    },
    {
      name: 'deliveryCountryInclusions',
      label: 'Country inclusions for shipping',
      type: 'select',
      defaultValue: [],
      choices: countryChoices,
    },
    {
      name: 'deliveryCountryExclusions',
      label: 'Country exclusions for shipping',
      type: 'select',
      defaultValue: [],
      choices: countryChoices,
    },
    {
      name: 'waiveShippingCountries',
      label: 'Waive Shipping Countries (effective zero packing units)',
      type: 'select',
      defaultValue: [],
      choices: countryChoices
    },
    {
      name: 'taxRate',
      label: 'VAT rate',
      type: 'select',
      choices: [
        {
          value: 'zero',
          label: 'Zero rated',
        },
        {
          value: 'standard',
          label: 'Standard 20%',
        },
      ],
      defaultValue: 'zero',
    },
    {
      name: 'minimumOrderQuantity',
      label: 'Minimum order quantity',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'maximumOrderQuantity',
      label: 'Maximum order quantity',
      type: 'number',
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      choices: [
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'deleted',
          label: 'Deleted',
        },
      ],
      defaultValue: 'active',
    },
    {
      name: 'availability',
      label: 'Availability',
      type: 'select',
      choices: [
        {
          value: 'purchasable',
          label: 'Purchasable',
        },
        {
          value: 'not_purchasable',
          label: 'Not purchasable'
        },
      ],
      defaultValue: 'purchasable',
    },
    {
      name: 'displayOrder',
      label: 'Display order',
      type: 'number',
    },
    {
      name: 'isPreOrder',
      label: 'Pre-order only?',
      type: 'checkbox',
    },
    {
      name: 'excludeFromSearch',
      label: 'Exclude from search',
      type: 'checkbox',
      defaultValue: true,
    },
    {
      name: 'isSticky',
      label: 'Sticky within search results?',
      type: 'checkbox',
    },
    {
      name: 'isDirectPaymentOnly',
      label: 'Direct payment only (not invoice)?',
      type: 'checkbox',
    },
    {
      name: 'isBundle',
      label: 'Product is a bundle containing other products',
      type: 'checkbox',
    },
    {
      name: 'productAttributeValues',
      label: 'Attributes',
      type: 'fieldGroup',
      childFields: [], // Populated in AddEditForm when the Product has been loaded
      asAccordion: false,
    },
    {
      name: 'membershipPlans',
      label: 'Gives access to membership plans',
      type: 'selectAsTable',
      choices: membershipPlanChoices,
      defaultValue: [],
    },
    {
      name: 'departments',
      label: 'Product departments',
      type: 'selectAsTable',
      choices: departmentChoices,
      defaultValue: [],
    },
    {
      name: 'productCategories',
      label: 'Product categories',
      type: 'selectAsTable',
      choices: productCategoryChoices,
      defaultValue: [],
    },
    {
      name: 'productReportingCategory',
      label: 'Product reporting category',
      type: 'select',
      choices: productReportingCategoryChoices,
    },
    {
      name: 'productOptions',
      label: 'Product specific options',
      type: 'fieldCollection',
      getHeading: productOptionGetHeading,
      childFields: [
        {
          name: 'name',
          label: 'Name',
        },
        {
          name: 'code',
          label: 'Code',
        },
        {
          name: 'description',
          label: 'Description',
        },
        {
          name: 'dataType',
          label: 'Type',
          type: 'select',
          choices: [
            {
              value: 'select',
              label: 'Select',
            },
            {
              value: 'text',
              label: 'Free text',
            },
          ],
        },
        {
          name: 'options',
          type: 'fieldCollection',
          getHeading: productOptionOptionGetHeading,
          childFields: [
            {
              name: 'value',
              label: 'Value',
            },
            {
              name: 'label',
              label: 'Label',
            },
          ],
        },
      ],
    },
    {
      name: 'orderDataFields',
      label: 'Order data fields',
      type: 'fieldCollection',
      getHeading: orderDataFieldsGetHeading,
      childFields: [
        {
          name: 'name',
          label: 'Name',
        },
        {
          name: 'type',
          label: 'Type',
          type: 'select',
          choices: [
            {
              value: 'select',
              label: 'Select',
            },
          ]
        },
        {
          name: 'label',
          label: 'Label',
        },
        {
          name: 'choices',
          type: 'fieldCollection',
          getHeading: orderDataFieldsChoicesGetHeading,
          childFields: [
            {
              name: 'value',
              label: 'Value',
            },
            {
              name: 'label',
              label: 'Label',
            },
          ]},
        {
          name: 'required',
          label: 'This field is required',
          type: 'checkbox',
        },
      ],
    },
    {
      name: 'productBundleParts',
      type: 'hidden',
      defaultValue: [],
    },
    {
      name: 'productGroupParts',
      type: 'hidden',
      defaultValue: [],
    },
    {
      name: 'productRelationships',
      type: 'hidden',
      defaultValue: [],
    },
  ];

  return fields;
}

const AddEditProduct = () => {
  const [initialised, setInitialised] = useState(false);
  const [productAttributeGroups, setProductAttributeGroups] = useState([]);
  const [fields, setFields] = useState(null);

  const { setShowLoadingSpinner } = useAppContext();
  const isMounted = useMounted();
  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const initialise = useCallback(async () => {
    if (isMounted) {
      setShowLoadingSpinner(true);
      const productAttributeGroupsResponse = await resourceApi.getResources({
        apiEndpoint: 'product-attribute-groups'
      });
      setProductAttributeGroups(productAttributeGroupsResponse.resources);

      const [
          productAttributeSetChoices,
          membershipPlanChoices,
          productCategoryChoices,
          productReportingCategoryChoices,
          departmentChoices
      ] = await Promise.all([
        requestApi.getResponse({ url: 'product-attribute-sets/choices' }),
        requestApi.getResponse({ url: 'membership-plans/choices' }),
        requestApi.getResponse({ url: 'product-categories/choices' }),
        requestApi.getResponse({ url: 'product-reporting-categories/choices?canHaveProductsAssigned=true' }),
        requestApi.getResponse({ url: 'departments/choices' })
      ]);

      setFields(buildFields(productAttributeSetChoices, membershipPlanChoices, productCategoryChoices, productReportingCategoryChoices, departmentChoices));
      setInitialised(true);
      setShowLoadingSpinner(false);
    }
  }, [isMounted]);

  useEffect(async () => {
    await initialise();
  }, [initialise]);

  if (!initialised) return '';

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .required('Type is required'),
    sku: Yup.string()
      .required('SKU is required'),
    slug: Yup.string()
      .required('Slug is required'),
    price: Yup.number()
      .typeError('Invalid price')
      .min(0, 'Price cannot be negative')
      .required('Price is required'),
    packingUnits: Yup.number()
      .typeError('Invalid packing units')
      .min(0, 'Packing units cannot be negative')
      .when('isPackingUnitsNull', {
        is: false,
        then: Yup.number().required("Packing units is required, or select 'No shipping'")
      }),
    status: Yup.string()
      .required('Status is required'),
    availability: Yup.string()
      .required('Availability is required'),
    minimumOrderQuantity: Yup.number()
      .typeError('Invalid minimum order quantity')
      .positive('Minimum order quantity must be positive')
      .required('Minimum order quantity is required'),
    maximumOrderQuantity: Yup.number()
      .typeError('Invalid maximum order quantity')
      .positive('Maximum order quantity must be positive'),
    productOptions: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('Name is required'),
        code: Yup.string()
          .required('Code is required'),
        dataType: Yup.string()
          .required('Data type is required'),
      }),
    ),
    productCategories: Yup.array().min(1)
  });

  // eslint-disable-next-line no-shadow
  const toFormValuesCustom = (resource, fields) => {
    const formValues = toFormValues(resource, fields);

    // Create form values for the productAttributeValues
    formValues.productAttributeValues = {};
    resource.productAttributeValues?.forEach((productAttributeValue) => {
      // Use the ProductAttribute code as the unique identifier - it's safe to do this
      // because no 2 ProductAttributes have the same code. Reverse this in toApiValuesCustom.
      formValues.productAttributeValues[productAttributeValue.productAttribute.code] = productAttributeValue.value;
    });

    if (resource?.packingUnits === null) {
      formValues.isPackingUnitsNull = true;
    } else {
      formValues.isPackingUnitsNull = false;
    }

    return formValues;
  };

  // eslint-disable-next-line no-shadow
  const toApiValuesCustom = (formValues, fields, resource) => {
    const apiValues = toApiValues(formValues, fields);

    // Create API values for the ProductAttributeValues
    const productAttributeValues = [];
    Object.keys(formValues.productAttributeValues).forEach((productAttributeCode) => {
      // Try to find an existing ProductAttributeValue for code
      const existingProductAttributeValue = resource.productAttributeValues?.find((pav) => pav.productAttribute.code === productAttributeCode);
      if (existingProductAttributeValue) {
        // Clone the existing ProductAttributeValue so as not to break the original
        const productAttributeValue = { ...existingProductAttributeValue };
        // If an ProductAttributeValue already exists, update it
        productAttributeValue.value = formValues.productAttributeValues[productAttributeCode];
        // Convert the full entity into an IRI (API complains otherwise)
        productAttributeValue.productAttribute = productAttributeValue.productAttribute['@id'];
        // Add the updated existing ProductAttributeValue
        productAttributeValues.push(productAttributeValue);
      } else {
        // There is no existing ProductAttributeValue, we need to create a new ProductAttributeValue.
        // First find the ProductAttribute by its code. NB: The first step is to find the relevant ProductAttributeGroup
        const productAttributeGroupContainingProductAttribute = productAttributeGroups.find(
          (pag) => pag.productAttributes.some((pa) => pa.code === productAttributeCode),
        );
        if (productAttributeGroupContainingProductAttribute) {
          const productAttribute = productAttributeGroupContainingProductAttribute.productAttributes.find(
            (pa) => pa.code === productAttributeCode,
          );
          // Create a ProductAttributeValue with the incoming value
          const productAttributeValue = {
            productAttribute: productAttribute['@id'],
            value: formValues.productAttributeValues[productAttributeCode],
          };
          // Add the new ProductAttributeValue
          productAttributeValues.push(productAttributeValue);
        }
      }
    });
    apiValues.productAttributeValues = productAttributeValues;

    if (formValues.isPackingUnitsNull) {
      apiValues.packingUnits = null;
    }

    return apiValues;
  };

  return (
    <AddEditResource
      addEditForm={AddEditProductForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={id}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
    />
  );
};

AddEditProduct.propTypes = {
};

AddEditProduct.defaultProps = {
}

export default AddEditProduct;
