import PropTypes from 'prop-types';
import * as Yup from 'yup';

// WRM
import AddEditResource from 'components/shared/AddEditResource';
import countriesArr from 'utils/countriesList';
import toApiValuesCustom from './to-api-values-custom';
import toFormValuesCustom from './to-form-values-custom';
import AddOrderForm from './AddOrderForm';

const apiEndpoint = 'baskets';
const resourceName = 'order';

const countryChoices  = countriesArr.map(choice => ({ value: choice[0], label: choice[1] }));

const fields = [
  {
    name: 'user',
    label: 'User',
    type: 'hidden',
  },
  {
    name: 'learningGroup',
    label: 'Learning group',
    type: 'select',
  },
  {
    name: 'deliveryAddress.firstName',
    label: 'First name',
    flex: 4,
  },
  {
    name: 'deliveryAddress.lastName',
    label: 'Last name',
    flex: 4,
  },
  {
    name: 'deliveryAddress.phone',
    label: 'Phone number',
    flex: 4,
  },
  {
    name: 'customerNote',
    label: 'Customer note',
    flex: 12,
  },
  {
    name: 'email',
    label: 'Email address',
    flex: 6,
  },
  {
    name: 'billingAddress.email',
    label: 'Invoice email address',
    flex: 6,
  },
  {
    name: 'manualDiscount',
    label: 'Discount',
    type: 'currency',
    flex: 3,
  },
  {
    name: 'manualShippingServiceName',
    label: 'Shipping service',
    flex: 4,
  },
  {
    name: 'manualShippingServiceAmount',
    label: 'Shipping price',
    type: 'currency',
    flex: 3,
  },
  {
    name: 'billingAddress.company',
    label: 'Company',
    flex: 4,
  },
  {
    name: 'billingAddress.line1',
    label: 'Address line 1',
    flex: 4,
  },
  {
    name: 'billingAddress.line2',
    label: 'Address line 2',
    flex: 4,
  },
  {
    name: 'billingAddress.townCity',
    label: 'Town/city',
    flex: 4,
  },
  {
    name: 'billingAddress.postcode',
    label: 'Postcode',
    flex: 4,
  },
  {
    name: 'billingAddress.country',
    label: 'Country',
    type: 'select',
    choices: countryChoices,
    defaultValue: 'GBR',
    flex: 4,
  },
  {
    name: 'deliveryAddress.company',
    label: 'Company',
    flex: 4,
  },
  {
    name: 'deliveryAddress.line1',
    label: 'Address line 1',
    flex: 4,
  },
  {
    name: 'deliveryAddress.line2',
    label: 'Address line 2',
    flex: 4,
  },
  {
    name: 'deliveryAddress.townCity',
    label: 'Town/city',
    flex: 4,
  },
  {
    name: 'deliveryAddress.postcode',
    label: 'Postcode',
    flex: 4,
  },
  {
    name: 'deliveryAddress.country',
    label: 'Country',
    type: 'select',
    choices: countryChoices,
    defaultValue: 'GBR',
    flex: 4,
  },
  {
    name: 'isQuote',
    label: 'This is a quote',
    type: 'checkbox'
  },
  {
    name: 'currency',
    defaultValue: 'GBP',
  },
  {
    name: 'paymentMethod',
    defaultValue: 'invoice',
  },
  {
    name: 'source',
    defaultValue: 'admin'
  },
];

const validationSchema = Yup.object().shape({
  user: Yup.string()
    .required('User is required'),
});

const AddOrder = (props) => {
  const {
    basketId,
  } = props;

  return (
    <AddEditResource
      addEditForm={AddOrderForm}
      apiEndpoint={apiEndpoint}
      fields={fields}
      id={basketId}
      resourceName={resourceName}
      toApiValuesCustom={toApiValuesCustom}
      toFormValuesCustom={toFormValuesCustom}
      validationSchema={validationSchema}
      showTitleText={false}
    />
  );
};

AddOrder.propTypes = {
  basketId: PropTypes.string,
};

AddOrder.defaultProps = {
  basketId: null,
}

export default AddOrder;
